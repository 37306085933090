.css-13cymwt-control {
    background-color: rgb(35 34 34) !important;
}
.selectoptionsdiv{
    border-color:  rgb(35 34 34) !important;
}

.seconddiv {
    /* border: 2px solid black; */
    /* background-color: aquamarine; */
    /* box-shadow: 1px 1px rgb(232, 230, 230); */
    margin: auto;
    margin-top: 10px;
    /* width: 200px; */
    height: 50px;
    display: flex;
    /* justify-content: space-evenly; */
    justify-content:space-between;
    align-items: center;
    color:grey
  }
  .dropdown_options{
    /* border:2px solid white */
  }
  #trials{
    /* color: white; */
  }